const savePage = async ({ userAuthResponse, userId }) => {
  try {
    const response = await fetch("/api/page", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userAuthResponse, userId }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const { data } = await response.json();

    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

const loginWithFacebook = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          resolve(response.authResponse);
        } else {
          reject(new Error("Facebook login failed"));
        }
      },
      {
        scope:
          "public_profile,email,pages_messaging,pages_show_list,pages_manage_metadata,business_management",
      }
    );
  });
};

const checkLoginState = () => {
  window.FB.getLoginStatus(({ authResponse, status }) => {
    if (status === "connected") {
      if (process.env.REACT_APP_NODE_ENV === "development") {
        console.log("Check - Logged in.");
      }
    } else {
      if (process.env.REACT_APP_NODE_ENV === "development") {
        console.log("Check - Not logged in.");
      }
    }

    return { status, authResponse };
  });
};

export { checkLoginState, savePage, loginWithFacebook };
