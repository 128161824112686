import { TableRow, TableCell } from "@mui/material";

import Iconify from "../../components/iconify/iconify";

import { IRecipient } from "../../types/broadcast.type";
import { useRouter } from "../../hooks";

// ----------------------------------------------------------------------

type Props = {
  row: IRecipient;
};

export default function RecipientTableRow({ row }: Props) {
  const router = useRouter();

  const { firstName, lastName, read24h, read7d, clicked, revenue, contactId } =
    row;

  const handleRowClick = () => {
    router.push(`/app/subscribers/${contactId}`);
  };

  return (
    <>
      <TableRow
        hover
        onClick={handleRowClick}
        sx={{
          fontWeight: 300,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
      >
        <TableCell>{firstName}</TableCell>
        <TableCell>{lastName}</TableCell>
        <TableCell
          sx={{
            color: "primary.main",
          }}
        >
          {read24h && <Iconify icon="material-symbols:check-circle-outline" />}
        </TableCell>
        <TableCell
          sx={{
            color: "primary.main",
          }}
        >
          {read7d && <Iconify icon="material-symbols:check-circle-outline" />}
        </TableCell>
        <TableCell
          sx={{
            color: "primary.main",
          }}
        >
          {clicked && <Iconify icon="material-symbols:check-circle-outline" />}
        </TableCell>
        <TableCell>{revenue}</TableCell>
      </TableRow>
    </>
  );
}
