import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useContext } from "react";

import Stack from "@mui/material/Stack";

import Container from "@mui/material/Container";

import Spinner from "../components/Spinner";

import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import { getBroadcast } from "../helpers/apiBroadcast";
import PageContext from "../context/pageContext";

import { IPageContext } from "../types/page.type";

import CampaignOverview from "../sections/broadcast/campaign-overview";
import CampaignRecipient from "../sections/broadcast/campaign-recipient";

import { Tabs, Tab } from "@mui/material";
import { RouterLink } from "../helpers/router-link";

//--------------------------------

export default function CampaignDetail() {
  const currentTab = window.location.href.split("/").pop();
  const params = useParams();
  const broadcastId = params.broadcastId ?? "";
  const TABS = [
    {
      label: "Overview",
      href: `/app/campaign/${broadcastId}/overview`,
      component: CampaignOverview,
    },
    {
      label: "Recipient",
      href: `/app/campaign/${broadcastId}/recipient`,
      component: CampaignRecipient,
    },
  ];

  const { pageId } = useContext(PageContext) as IPageContext;

  // QUERY
  const { isLoading, data } = useQuery({
    queryKey: ["broadcast", pageId, broadcastId],
    queryFn: () => getBroadcast({ pageId, broadcastId }),
    retry: 1,
  });

  const broadcast = data?.data?.broadcasts[0];

  const { broadcastName } = broadcast || {};

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          direction="column"
          alignItems="left"
          sx={{
            mb: { xs: 3, md: 5 },
            padding: "1rem",
          }}
        >
          <CustomBreadcrumbs
            heading="Campaigns"
            links={[
              {
                name: "Home",
                href: "/app/home",
              },
              {
                name: "Campaigns",
                href: "/app/campaign",
              },
              {
                name: broadcastName,
              },
            ]}
          />
          <Tabs value={currentTab}>
            {TABS.map((tab) => (
              <Tab
                key={tab.label}
                component={RouterLink}
                href={tab.href}
                label={tab.label}
                value={tab.label.toLowerCase()}
              />
            ))}
          </Tabs>

          {TABS.map((tab) => {
            if (tab.label.toLowerCase() === currentTab) {
              return <tab.component key={tab.label} broadcast={broadcast} />;
            }
            return null;
          })}
        </Stack>
      </Container>
    </>
  );
}
