import { createContext, useMemo, useCallback, useReducer } from "react";

import { getConnectedPages } from "../helpers/apiPage";
import { loginUser, createUser } from "../apis/apiAuth";

import {
  IAuthContext,
  AuthUserType,
  ActionMapType,
  AuthStateType,
} from "../types/auth.type";

type Props = {
  children: React.ReactNode;
};

enum Types {
  INITIAL = "INITIAL",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOGOUT = "LOGOUT",
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const res = await loginUser({ email, password });

    const {
      user: { userId },
    } = res.data;
    localStorage.setItem("userId", userId);

    // get connected pages
    const {
      data: { connectedPages },
    } = await getConnectedPages({ userId });
    localStorage.setItem("connectedPages", JSON.stringify(connectedPages));

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          userId,
          connectedPages,
        },
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, name: string) => {
      const res = await createUser({ name, email, password });
      const {
        user: { userId },
      } = res.data;

      localStorage.setItem("userId", userId);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            userId,
          },
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("connectedPages");
    localStorage.removeItem("defaultPage");

    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
