import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";

import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Label from "../../components/label";
import Button from "@mui/material/Button";

import { useState, useEffect } from "react";

import { IBroadcastButton } from "../../types/broadcast.type";

import { useQueryClient } from "@tanstack/react-query";

import { getMessageStatsForBroadcast } from "../../helpers/apiBroadcast";

//---------

type StatusProp = {
  statusText: string;
  statusColor:
    | "info"
    | "success"
    | "error"
    | "warning"
    | "default"
    | "primary"
    | "secondary";
};

//---------

export default function CampaignOverview({
  broadcast,
}: {
  broadcast: IBroadcastButton;
}) {
  const [status, setStatus] = useState({} as StatusProp);
  const broadcastId = broadcast.broadcastId;

  const queryClient = useQueryClient();

  const {
    buttonUrl,
    buttonPrompt,
    fileLink,
    fileType,
    broadcastText,
    broadcastTime,
    broadcastStatus,
    broadcastRecipientCount,
    broadcastReadCount24Hour,
    broadcastReadCount7Day,
    broadcastClickCount,
  } = broadcast || {};

  const broadcastTimeInLocale = new Date(broadcastTime).toLocaleDateString([], {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });

  queryClient.prefetchQuery({
    queryKey: ["messageStats", broadcastId],
    queryFn: () => getMessageStatsForBroadcast({ broadcastId }),
  });

  useEffect(() => {
    statusConvertFull(broadcastStatus);
  }, [broadcastStatus]);

  const statusConvertFull = (status: String) => {
    switch (status) {
      case "0":
        setStatus({
          statusText: "Draft",
          statusColor: "secondary",
        });
        break;
      case "1":
        setStatus({
          statusText: "Scheduled",
          statusColor: "info",
        });
        break;
      case "2":
        setStatus({
          statusText: "Sending",
          statusColor: "success",
        });
        break;
      case "3":
        setStatus({
          statusText: "Sent",
          statusColor: "success",
        });
        break;
      case "4":
        setStatus({
          statusText: "Canceled",
          statusColor: "error",
        });
        break;
      case "5":
        setStatus({
          statusText: "Ongoing",
          statusColor: "success",
        });
        break;
      default:
        setStatus({
          statusText: "Unknown",
          statusColor: "secondary",
        });
        break;
    }
  };

  const renderAnalytics = (
    <Card>
      <CardHeader title="Details" />

      <Stack spacing={2.5} sx={{ p: 3 }}>
        <Label variant="soft" sx={{ maxWidth: 100 }} color={status.statusColor}>
          {status.statusText}
        </Label>

        <Grid container spacing={0}>
          <Grid
            component="div"
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Message delivered on:&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastTimeInLocale}
            </Typography>
          </Grid>

          <Grid
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Recipients:&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastRecipientCount}
            </Typography>
          </Grid>

          <Grid
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Read (24Hours):&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastReadCount24Hour}
            </Typography>
          </Grid>

          <Grid
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Read (7Days):&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastReadCount7Day}
            </Typography>
          </Grid>

          <Grid
            xs={12}
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="body2" color="textSecondary">
              Unique Clicks:&nbsp;
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {broadcastClickCount}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );

  const renderSummary = (
    <Card>
      <CardHeader title="Content" />

      <Stack spacing={2.5} sx={{ p: 3, alignItems: "center" }}>
        {fileType && (
          <Box
            component={fileType === "image" ? "img" : "video"}
            controls={fileType === "video"}
            src={fileLink}
            sx={{
              maxWidth: 350,
              height: "auto",
              objectFit: "contain",
              borderRadius: 1.5,
            }}
          />
        )}

        <Typography
          variant="body2"
          sx={{
            border: "1px solid #e0e0e0",
            p: 1.5,
            borderRadius: 1.5,
            width: 350,
          }}
        >
          {broadcastText}
        </Typography>

        {buttonPrompt && buttonUrl && (
          <>
            <Button
              variant="contained"
              color="primary"
              href={buttonUrl}
              target="_blank"
              sx={{ width: 350 }}
            >
              {buttonPrompt}
            </Button>
            <Button
              variant="text"
              color="primary"
              href={buttonUrl}
              target="_blank"
            >
              {buttonUrl}
            </Button>
          </>
        )}
      </Stack>
    </Card>
  );

  return (
    <>
      {renderSummary}
      {renderAnalytics}
    </>
  );
}
