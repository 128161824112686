import { useContext, useEffect } from "react";
import { useIsFetching } from "@tanstack/react-query";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

import { useRouter } from "../../hooks";

import { useOffSetTop, useResponsive } from "../../hooks/";

import PageContext from "../../context/pageContext";

import { NAV, HEADER } from "../config-layout";

import Iconify from "../../components/iconify";

import { IPageContext } from "../../types/page.type";

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const lgUp = useResponsive("up", "lg");

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset;

  const router = useRouter();

  const { fbPage } = useContext(PageContext) as IPageContext;

  useEffect(() => {
    if (fbPage) {
      if (!fbPage.registrationComplete) {
        router.push("/app/activation");
      }
    }
  }, [fbPage, router]);

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <Iconify icon="material-symbols:menu" width={24} />
        </IconButton>
      )}
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        bgcolor: theme.palette.background.default,
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
      {useIsFetching() > 0 && <LinearProgress />}
    </AppBar>
  );
}
