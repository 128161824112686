import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import {
  Table,
  TableBody,
  TableContainer,
  CircularProgress,
} from "@mui/material";

import RecipientTableRow from "./campaign-recipient-table-row";

import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { useTable, TableNoData, TableHeadCustom } from "../../components/table";

import { IBroadcastButton } from "../../types/broadcast.type";

import { getMessageStatsForBroadcast } from "../../helpers/apiBroadcast";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "read24h", label: "Read (24hours)" },
  { id: "read7d", label: "Read (7days)" },
  { id: "clicked", label: "Clicked" },
  { id: "revenue", label: "Revenue" },
];

// ----------------------------------------------------------------------

export default function CampaignRecipient({
  broadcast,
}: {
  broadcast: IBroadcastButton;
}) {
  const table = useTable();

  const [tableData, setTableData] = useState<any[]>([]);

  const broadcastId = broadcast.broadcastId;
  const { data, isLoading } = useQuery({
    queryKey: ["messageStats", broadcastId],
    queryFn: () => getMessageStatsForBroadcast({ broadcastId }),
    retry: 1,
  });

  useEffect(() => {
    setTableData(data?.data.messageStats);
  }, [data]);

  const renderSummary = (
    <Card>
      <CardHeader title="Recipients" />

      <Stack spacing={2.5} sx={{ p: 3 }}>
        <TableContainer
          sx={{ position: "relative" }}
          style={{ marginBottom: "0px" }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Table size={"small"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
              />
              <TableBody style={{ marginBottom: "0px" }}>
                {tableData?.length > 0 ? (
                  tableData?.map((row, index) => (
                    <RecipientTableRow key={index} row={row} />
                  ))
                ) : (
                  <TableNoData notFound={true} />
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Stack>
    </Card>
  );

  return <>{renderSummary}</>;
}
