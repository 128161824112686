import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRouter } from "../hooks";
import Link from "@mui/material/Link";

import { loginWithFacebook, savePage } from "../helpers/fbServices";
import Iconify from "../components/iconify";

// ----------------------------------------------------------------------

export default function ConnectPageNew() {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = localStorage.getItem("userId") !== null;
  const connectedPages = localStorage.getItem("connectedPages");
  //if connectedPages is not null and not an empty object, isAuth = true
  const isAuth = connectedPages !== null && connectedPages !== "{}";

  useEffect(() => {
    //fb plugin
    window.FB.XFBML.parse();
    //if user isn't logged in, redirect them to root
    if (!isLoggedIn) {
      router.push("/");
    }
    //if isAuth is true then there is at least one activated page
    //redirect user to home, else welcomr page

    if (isAuth) {
      router.push("/app/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performConnect = async () => {
    try {
      setIsLoading(true);
      const userAuthResponse = await loginWithFacebook();

      const userId = localStorage.getItem("userId") || "";

      const { connectedPages } = await savePage({ userAuthResponse, userId });

      localStorage.setItem("connectedPages", JSON.stringify(connectedPages));

      router.push("/welcome");
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 4, position: "relative" }}>
      <Typography variant="h4">Connect Facebook page</Typography>
    </Stack>
  );

  const renderButton = (
    <Stack spacing={2} sx={{ mb: 4, position: "relative" }}>
      <LoadingButton
        fullWidth
        loading={isLoading}
        onClick={performConnect}
        color="secondary"
        size="large"
        type="submit"
        variant="contained"
        startIcon={<Iconify icon={"bi:facebook"} />}
      >
        Continue with Facebook
      </LoadingButton>
    </Stack>
  );

  const renderEnd = (
    <Stack spacing={2} sx={{ mb: 2, position: "relative" }}>
      <Typography variant="body2">
        Connecting your Facebook page allows you to collect Messenger
        subscribers and broadcast messages.
      </Typography>
      <Typography variant="body2">
        This only needs to be done once and can be updated/removed later.
      </Typography>
    </Stack>
  );

  const renderLink = (
    <Link
      // href="/auth/login-new"
      href="/"
      variant="body2"
      color="primary"
      sx={{ alignSelf: "center", marginTop: "10px" }}
    >
      &#8592; Back to Login
    </Link>
  );

  return (
    <>
      {renderHead}
      {renderButton}
      {renderEnd}
      {renderLink}
    </>
  );
}
