import {
  Card,
  Stack,
  Typography,
  Link,
  List,
  ListItem,
  Container,
} from "@mui/material";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";

import { useContext, useEffect } from "react";

import { useRouter } from "../hooks";

import PageContext from "../context/pageContext";
import { IPageContext } from "../types/page.type";

export default function ActivatePage() {
  const router = useRouter();

  const { fbPage } = useContext(PageContext) as IPageContext;

  useEffect(() => {
    if (fbPage) {
      if (fbPage.registrationComplete) {
        router.push("/app/home");
      }
    }
  }, [fbPage, router]);

  return (
    <Container maxWidth="lg">
      <Stack
        spacing={2}
        direction="column"
        alignItems="left"
        sx={{
          mb: { xs: 3, md: 5 },
          padding: "1rem",
        }}
      >
        <CustomBreadcrumbs heading="Activate Facebook Page" links={[]} />

        <Card sx={{ minWidth: "900px" }}>
          <Stack spacing={2.5} sx={{ p: 3 }}>
            <Typography>
              Each Page needs to be activated within facebook messenger. Follow
              the below steps to activate {fbPage?.pageName}.
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  1. Click{" "}
                  <Link
                    href={`https://m.me/${fbPage?.pageId}?text=1sendRegistration`}
                    target="_blank"
                    sx={{ color: "blue", textDecoration: "underline" }}
                  >
                    here
                  </Link>{" "}
                  to send "1sendRegistration" to {fbPage?.pageName} on Facebook
                  Messenger
                </Typography>
              </ListItem>

              <ListItem>
                <Typography>
                  2. After receiving a "Subscribe message", click "Get Updates"
                </Typography>
              </ListItem>

              <ListItem>
                <Typography>
                  Please refer to{" "}
                  <Link
                    href="https://docs.1send.net/getting-started/getting-set-up"
                    target="_blank"
                    sx={{ color: "blue", textDecoration: "underline" }}
                  >
                    documentation
                  </Link>{" "}
                  for any questions. If you need additional assistance, reach
                  out to m@1send.net
                </Typography>
              </ListItem>
            </List>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
