import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRouter, useSearchParams, useAuthContext } from "../hooks";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function PrivateRoute({ children }: Props) {
  const [isAuth, setIsAuth] = useState(false);
  const connectedPages = localStorage.getItem("connectedPages");

  const userId = localStorage.getItem("userId");

  const navigate = useNavigate();

  useEffect(() => {
    if (!userId || userId === null) {
      navigate("/");
    } else if (!connectedPages || connectedPages === "{}") {
      navigate("/connect");
    }
    setIsAuth(true);
  }, [navigate, userId, connectedPages]);

  return <>{isAuth && children}</>;
}

// ----------------------------------------------------------------------

function Container({ children }: Props) {
  const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get("returnTo") || "/";

  const { authenticated } = useAuthContext();

  const check = useCallback(() => {
    if (authenticated) {
      router.replace(returnTo);
    }
  }, [authenticated, returnTo, router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}

function ContainerAuth({ children }: Props) {
  const router = useRouter();

  const { authenticated } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      const href = `/?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, router]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
